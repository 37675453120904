@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800&display=swap');

* {
  font-family: 'Inter';
  font-style: normal;
  box-sizing: border-box !important;
  scroll-behavior: auto !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F3F4;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
textarea {
  border: 1px solid transparent !important;
}

input:focus,
input:focus-visible,
textarea:focus {
  border: 1px solid #9747FF !important;
  box-shadow: unset !important;
}

select {
  border: 1px solid transparent !important;
}

select:focus,
select:focus-visible {
  border: 1px solid #9747FF !important;
  box-shadow: unset;
  outline: unset;
}

input[type="checkbox"] {
  background-color: transparent;
  border-radius: 4px !important;
  border-width: 2px;
}

input[type="checkbox"]:focus {
  outline: none;
  box-shadow: none;
}

input[type="checkbox"]:checked {
  background-color: #9747FF;
}

.Toastify__toast-container {
  bottom: 120px;
  right: 20px;
}

.Toastify__close-button {
  display: none;
}

.toast-gray {
  border-radius: 8px;
  border: 1px solid var(--empty-state-text, #BEC5CF);
  background: var(--grey-2, #E2E8F0);
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  min-height: 50px;
}

.toast-body-gray {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: unset;
}

.toast-gradient {
  border-radius: 8px;
  background: radial-gradient(115.73% 550.42% at 0% 40%, rgb(141, 227, 255) 0%, rgb(255, 235, 186) 28.85%, rgb(255, 136, 243) 55.10%, rgb(186, 134, 255) 100%);
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  min-height: 50px;
}

.toast-body-gradient {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: unset;
}

.Toastify__toast-container--top-left.Toastify__toast-container {
  top: unset;
  right: 15px;
  left: unset;
  bottom: 15px;
}

.rdrStaticRange {
  border-bottom: none !important;
  height: 35px !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel {
  border-radius: 8px !important;
  background: #EDF3F9 !important;
  transition: 0.3s;
  transition-property: background;
}

.rdrDefinedRangesWrapper {
  padding: 8px !important;
  padding-top: 16px !important;
}

.rdrStaticRangeSelected {
  border-radius: 8px !important;
  background: #F5EDFF !important;
  overflow: hidden !important;
}

.rdrStaticRangeLabel {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  padding: 8px !important;
  transition: 0.3s;
  transition-property: background;
}

.rdrStaticRangeSelected .rdrStaticRangeLabel {
  color: #9747FF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.rdrDayNumber {
  color: #000;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  top: 0px;
  left: 0px;
  bottom: unset;
  right: unset;
  height: 100%;
  display: flex;
}

.rdrDayPassive .rdrDayNumber {
  color: #7D8898;
}

.rdrDayPassive .rdrDayNumber span {
  color: white;
}

.rdrDayToday .rdrDayNumber span:after {
  height: 0px !important;
}

.rdrStartEdge {
  border-radius: 8px 0px 0px 8px !important;
  background: #9747FF;
  color: #FFF;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

button:not(.rdrDayPassive) .rdrInRange,
button:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
  background: #F5EDFF;
  color: #000 !important;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rdrEndEdge {
  border-radius: 0px 8px 8px 0px !important;
  background: #9747FF;
  color: #FFF;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rdrEndEdge+span span,
.rdrStartEdge+span span,
.rdrInRange+span span {
  color: #FFF !important;
}

.rdrStartEdge.rdrEndEdge {
  border-radius: 8px !important;
  background: #9747FF;
  color: #FFF;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rdrEndEdge,
.rdrStartEdge,
.rdrInRange {
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  right: unset !important;
  bottom: unset !important;
  width: 100% !important;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrInRange {
  border-radius: unset !important;
}

.rdrDateDisplayWrapper {
  background-color: #FFFFFF !important;
}

.rdrDateDisplayItem input {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rdrDateDisplayItem input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.rdrDefinedRangesWrapper {
  width: 170px !important;
}

.rdrNextPrevButton {
  display: flex;
  width: 26px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.rdrNextPrevButton i {
  margin: 0 !important;
}

.rdrNextPrevButton:hover {
  background: #EDF3F9;
}

.rdrNextButton i,
.rdrPprevButton i {
  display: none !important;
}

.rdrPprevButton::before {
  content: "";
  background-image: url('./assets/icons/analytics-arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 14px;
  height: 100%;
  display: flex;
}

.rdrNextButton::after {
  content: "";
  background-image: url('./assets/icons/analytics-arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: rotate(-180deg);
  width: 14px;
  height: 100%;
  display: flex;
}

.rdrWeekDay {
  color: #7D8898;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.rdrMonthName {
  color: #7D8898;
  text-align: start;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.rdrYearPicker select,
.rdrYearPicker option,
.rdrMonthPicker select,
.rdrMonthPicker option {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: start;
}

.rdrYearPicker select,
.rdrMonthPicker select {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  text-align: center !important;
}

.rdrMonths {
  padding-bottom: 70px;
}

.rdrDay {
  height: 35px !important;
}

.rdrDayNumber:hover {
  border-radius: 8px;
  border: 1px solid #141315;
}

.rdrDayToday .rdrDayNumber {
  border-radius: 8px;
  border: 1px solid #141315 !important;
}

.rdrDay .rdrStartEdge+.rdrDayNumber,
.rdrDay .rdrEndEdge+.rdrDayNumber,
.rdrDay .rdrInRange+.rdrDayNumber {
  border: none;
}

.rdrCalendarWrapper {
  width: 550px;
}

.rdrWeekDays {
  padding-bottom: 8px !important;
}

.analytics-block-table-mobile-content-wrapper .progress,
.analytics-block-table .progress {
  height: 8px;
}

.analytics-block-table-mobile-content-wrapper .progress-bar,
.analytics-block-table .progress-bar {
  background: linear-gradient(to right, #EB72A5 0%, #9747FF 100%);
  border-radius: 8px;
}

.analytics-block-table-compare tr:first-child .progress-bar {
  background: #EB72A5;
}

.analytics-block-table-compare tr:last-child .progress-bar {
  background: #9747FF;
}

.analytics-header-date-calendar-wrapper.empty-range .rdrInRange,
.analytics-header-date-calendar-wrapper.empty-range .rdrDayNumber span {
  background: transparent !important;
}

.rdrDayToday .rdrEndEdge+span,
.rdrDayToday .rdrStartEdge+span,
.rdrEndEdge+span,
.rdrStartEdge+span {
  border: none !important;
}

.Toastify__progress-bar {
  height: 8px;
  background: rgba(39, 174, 96, 1);
  transform-origin: right;
}

.toast-body-gray.static-created {
  padding: 8px;
}

.toast-body-gradient.dynamic-created {
  padding: 8px;
  padding-bottom: 10px;
}

.toast-body-gray .static-toast-block {
  color: rgba(20, 19, 21, 1);
  padding-bottom: 40px;
}

.toast-body-gray.static-created .Toastify__toast-icon {
  position: relative;
  top: -20px;
}

.Toastify__progress-bar {
  left: unset !important;
  right: 0;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.toast-enter {
  animation: Toastify__enterBottom 0.5s ease-in-out;
}

.toast-exit {
  animation: Toastify__outRight 0.5s ease-in;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes Toastify__enterBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes Toastify__outRight {
  20% {
    transform: translate3d(-20px, 0, 0);
  }

  to {
    transform: translate3d(2000px, 0, 0);
    visibility: hidden;
  }
}

@media (max-width: 900px) {
  .Toastify__toast-container {
    bottom: 15px;
    right: 15px;
    width: 90%;
    left: unset;
  }

  .Toastify__toast-container--top-right.Toastify__toast-container {
    top: 58px;
    left: 15px;
    right: unset;
    bottom: unset;
  }

  .Toastify__toast-container--top-left.Toastify__toast-container,
  .Toastify__toast-container--top-left.Toastify__toast-container {
    top: 78px;
    left: 15px;
    right: unset;
    bottom: unset;
  }

  .mobile-analytics-date-range .rdrCalendarWrapper {
    width: unset;
  }

  .mobile-analytics-date-range .rdrMonths {
    padding-bottom: 0px;
  }

  .mobile-analytics-date-range .rdrMonth {
    padding: 0 !important;
  }

  .mobile-analytics-date-range .rdrMonthAndYearWrapper {
    display: none;
  }

  .mobile-analytics-date-range .rdrMonthName {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #141315;
  }

  .mobile-analytics-date-range .rdrDefinedRangesWrapper {
    width: 100% !important;
    border-right: none !important;
    padding: 0 !important;
    padding-top: 16px !important;
  }

  .mobile-analytics-date-range .rdrStaticRanges {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 8px;
    padding-bottom: 8px;
  }

  .mobile-analytics-date-range .rdrStaticRange {
    white-space: nowrap;
    height: 36px !important;
    border: 1px solid #E2E8F0 !important;
    padding: 8px 12px;
    border-radius: 8px;
  }

  .mobile-analytics-date-range .rdrStaticRange span {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    padding: 0 !important;
  }

  .mobile-analytics-date-range .rdrInputRanges {
    display: none;
  }

  .mobile-analytics-date-range .rdrStaticRangeSelected {
    overflow: unset !important;
  }

  .mobile-analytics-date-range .rdrStaticRange:hover .rdrStaticRangeLabel {
    background-color: transparent !important;
  }

  .mobile-analytics-date-range.empty-range .rdrInRange,
  .mobile-analytics-date-range.empty-range .rdrDayNumber span {
    background: transparent !important;
  }

  .Toastify__toast.dynamic-created,
  .Toastify__toast.static-created {
    width: calc(100vw - 32px);
  }
}
.qr-code-scanning {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: white;
}

/*  Dynamic App  */

.app-wrapper {
  min-height: 100%;
  width: 100%;
  background-color: rgb(210, 143, 89);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}

.app-logo-wrapper {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.app-logo {
  width: 60px;
  height: 60px;
}

.app-name {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.app-description {
  margin-top: 20px;
  text-align: center;
}

.app-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.app-download-button {
  background-color: black;
  color: white;
  border-radius: 6px;
  border: none;
  width: 180px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-size: 14px;
  gap: 10px;
}

.app-download-button-image {
  width: 30px;
  height: 30px;
}

.app-download-button-text-block {
  display: flex;
  flex-direction: column;
}

.app-download-button-text-small {
  font-size: 9px;
  font-weight: 500;
  text-align: start;
}

.app-download-button-text-big {
  text-align: start;
  font-size: 14px;
  font-weight: 500;
}

.app-site-link {
  margin-top: 10px;
  font-size: 14px;
}

.app-form-site-color {
  display: flex;
  flex-direction: column;
}

/* Dynamic Social Media */

.social-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}

.social-header-text {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
}

.socials-list-block {
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  max-width: 400px;
  gap: 15px;
  margin-top: 20px;
}

.social-list-item {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: rgb(206, 239, 239);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s ease;
}

.social-list-item:hover {
  transform: scale(1.1);
  background-color: rgb(190, 225, 225);
  transition: 0.5s ease;
}

.social-header-description {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}

/* Dynamic Profile Card */

.profile-card-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}

.profile-card-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.profile-card-header {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 15px;
}

.profile-card-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-card-name {
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
}

.profile-card-job-title {
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.profile-card-contact-information {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.profile-card-information-title {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #334155;
}

.profile-card-information-value {
  font-size: 13px;
  font-weight: 400;
  margin-left: 4px;
  margin-bottom: 10px;
  color: #334155;
}

.profile-socials-list-block {
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  max-width: 400px;
  gap: 15px;
}

.profile-social-list-item {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s ease;
}

/* Dynamic Event */

.event-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}

.event-logo-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-logo-image {
  height: 100px;
  width: 100px;
  border-radius: 20px;
  object-fit: cover;
  margin-top: 10px;
}

.event-name {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 15px;
}

.event-summary {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
}

.event-information-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}

.event-information-bold-title {
  font-size: 16px;
  font-weight: 600;
}

.event-information-title {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 15px;
}

.event-information-value {
  font-size: 13px;
  font-weight: 400;
}

.event-information-services {
  display: flex;
  align-items: center;
  gap: 5px;
}

.event-additional-information {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.event-border-line {
  width: 100%;
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.412);
  margin: 20px 0;
}

/* Dynamic Website */

.dynamic-website-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
}

.dynamic-website-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #f9f9f9f0;
  padding: 10px;
  padding-top: 25px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.dynamic-grey-image-block {
  width: 100%;
  height: 121px;
  flex-shrink: 0;
  border-radius: 14px;
  margin-top: 20px;
  background: var(--grey-2, #e2e8f0);
}

.website-preview-url-block {
  height: 23px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: #00000014;
  width: 100%;
  justify-content: center;
  padding: 0 25px;
}

.website-preview-url-block span {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dynamic-website-menu {
  position: absolute;
  top: 30px;
  left: 17px;
  width: 10px;
}

.dynamic-website-reload {
  position: absolute;
  top: 30px;
  right: 17px;
  width: 10px;
}

/* Dynamic PDF */

.qr-code-scanning .pdf-preview-wrapper {
  margin-top: 0px;
  min-height: 100vh;
}

.pdf-preview-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -22px;
}

.pdf-preview-upper-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 44px 16px 80px 16px;
}

.pdf-preview-lower-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 16px;
}

.pdf-logo-block {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.pdf-logo {
  width: 80px;
  height: 80px;
  border-radius: 100px;
}

.pdf-logo-empty {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  background-color: #334155;
}

.pdf-company-name {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  max-width: 400px;
}

.pdf-title-text {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  max-width: 400px;
}

.pdf-company-description {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
}

.pdf-button {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--off-black, #7d8898);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  max-width: 400px;
  width: 100%;
}

.pdf-image-block {
  width: 100%;
  min-height: 127px;
  flex-shrink: 0;
  border-radius: 14px;
  background: var(--white, #fff);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
  max-width: 400px;
  overflow: hidden;
  margin-top: -70px;
}

.pdf-website-url {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 400px;
}

.pdf-preview-img {
  width: 100%;
  min-height: 127px;
}

/* Dynamic MP3 */

.qr-code-scanning .mp3-preview-wrapper {
  margin-top: 0px;
  min-height: 100vh;
}

.mp3-preview-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -22px;
}

.mp3-preview-upper-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 44px 16px 80px 16px;
  background-color: #e2e8f0;
  align-items: center;
}

.mp3-preview-title {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  max-width: 400px;
}

.mp3-preview-description {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
}

.mp3-preview-button {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--off-black, #7d8898);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  max-width: 400px;
  width: 100%;
}

.mp3-preview-lower-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 16px;
  gap: 16px;
}

.mp3-image-block {
  width: 100%;
  min-height: 120px;
  flex-shrink: 0;
  border-radius: 14px;
  background: var(--white, #fff);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
  overflow: hidden;
  margin-top: -75px;
  max-width: 400px;
}

.mp3-audio {
  width: 100%;
  height: 40px;
  max-width: 400px;
}

.mp3-website-url {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 400px;
}

.mp3-logo {
  width: 100%;
  min-height: 120px;
}

/* Dynamic Video */

.qr-code-scanning .video-preview-wrapper {
  margin-top: 0px;
  min-height: 100vh;
}

.video-preview-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -22px;
}

.video-preview-upper-block {
  padding: 44px 16px 80px 16px;
  background-color: #e2e8f0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.video-preview-lower-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px;
}

.video-company-logo-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.video-logo {
  width: 80px;
  height: 80px;
  border-radius: 100px;
}

.video-logo-empty {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  background-color: #334155;
}

.video-company-name {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  max-width: 400px;
}

.video-title-text {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  max-width: 400px;
}

.video-description {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
}

.video-button {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--off-black, #7d8898);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  max-width: 400px;
  width: 100%;
}

.video-preview-block {
  width: 100%;
  min-height: 120px;
  flex-shrink: 0;
  border-radius: 14px;
  background: var(--white, #fff);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  max-width: 400px;
}

.video-preview-item-wrapper {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
  border-radius: 14px;
  overflow: hidden;
}

.video-preview-block-no-background {
  width: 100%;
  min-height: 120px;
  flex-shrink: 0;
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  align-items: center;
}

.video-previwe-website {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 400px;
}

/* Dynamic Image */

.qr-code-scanning .image-preview-wrapper {
  margin-top: 0px;
  min-height: 100vh;
}

.image-preview-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -22px;
  background-color: #e2e8f0;
}

.image-preview-upper-block {
  padding: 44px 16px 80px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: #ffffff;
}

.image-preview-lower-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px;
  background-color: #e2e8f0;
  height: 100%;
}

.image-previwe-title {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  max-width: 400px;
}

.image-preview-description {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
}

.image-preview-button {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--off-black, #7d8898);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  max-width: 400px;
  width: 100%;
}

.image-preview-block-img-wrapper {
  width: 100%;
  min-height: 120px;
  flex-shrink: 0;
  border-radius: 14px;
  background: var(--white, #fff);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
  overflow: hidden;
  max-width: 400px;
}

.image-preview-block-img-wrapper-no-shadow {
  width: 100%;
  min-height: 120px;
  flex-shrink: 0;
  border-radius: 14px;
  overflow: hidden;
  max-width: 400px;
}

.image-preview-block-img-wrapper-no-shadow:nth-child(1) {
  width: 100%;
  min-height: 120px;
  margin-top: -75px;
}

.image-preview-block-img-wrapper:nth-child(1) {
  width: 100%;
  min-height: 120px;
  margin-top: -75px;
}

.image-preview-img {
  width: 100%;
  min-height: 120px;
}

.image-preview-website {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 400px;
}

/* Dynamic Wifi */

.qr-code-scanning .wifi-preview-wrapper {
  margin-top: 0px;
  min-height: 100vh;
}

.wifi-preview-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -22px;
  background-color: #ffffff;
}

.wifi-preview-upper-block {
  padding: 44px 16px 140px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: #ffffff;
  min-height: 50%;
}

.wifi-preview-lower-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  height: 100%;
}

.wifi-logo-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wifi-logo {
  width: 80px;
  height: 80px;
  border-radius: 100px;
}

.wifi-preview-title {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  max-width: 400px;
}

.wifi-preview-network-name {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
}

.wifi-preview-block-img-wrapper-no-shadow {
  width: 100%;
  min-height: 120px;
  flex-shrink: 0;
  border-radius: 14px;
  overflow: hidden;
  max-width: 400px;
  margin-top: -145px;
}

.wifi-preview-img {
  width: 100%;
  min-height: 120px;
}

.wifi-preview-password-block {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 400px;
}

.wifi-preview-password-input {
  display: flex;
  width: calc(100% - 52px);
  height: 44px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  border-radius: 8px;
  background: var(--grey-1, #f1f5f9);
  font-weight: 400;
  line-height: 24px;
  border: none;
}

.wifi-preview-password-input:focus,
.wifi-preview-password-input:active,
.wifi-preview-password-input:hover {
  outline: none;
  box-shadow: none;
}

.wifi-preview-cope-btn {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--grey-2, #e2e8f0);
  background: var(--white, #ffffff);
}

/* Dynamic Text */

.qr-code-scanning .text-preview-wrapper {
  margin-top: 0px;
  min-height: 100vh;
}

.qr-code-scanning .text-preview-upper-block {
  min-height: 50vh;
}

.text-preview-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -22px;
  height: 100%;
  background-color: white;
}

.text-preview-upper-block {
  padding: 40px 16px 16px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: #e2e8f0;
  min-height: 55%;
}

.text-preview-lower-block {
  background-color: #ffffff;
}

.text-preview-logo-block {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.text-preview-logo {
  width: 80px;
  height: 80px;
  border-radius: 100px;
}

.text-preview-title {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
}

.text-preview-subject {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  max-width: 400px;
}

.text-preview-company {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  max-width: 400px;
}

/*  STYLES for version 2 codes */

.square-aspect-ratio {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.youtube-aspect-ratio {
  aspect-ratio: 16 / 9;
}
